import React, { useEffect } from "react";

const Banner = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <section>
        <div className="bg-2">
          <img src="/assets/Banner-2.png" className="img-fluid" alt="img" />
        </div>
      </section>

      <section>
        <div className="maps">
         

          <iframe
            style={{ border: 0 }}
            className="map-frames"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3000.4088050592413!2d80.29306957507882!3d13.108162887219958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA2JzI5LjQiTiA4MMKwMTcnNDQuMyJF!5e1!3m2!1sen!2sin!4v1739353363270!5m2!1sen!2sin"
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Banner;
